<template>
  <v-container fluid fill-height>
    <NavBar></NavBar>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card outlined style="margin-top:-30px;">
          <v-card-text>
            <template v-if="tokenValid">
              <p class="text-center">Please enter your new password below:</p>
              <v-form @submit.prevent="resetPassword">
                <v-text-field
                  v-model="password"
                  label="New Password"
                  outlined
                  dense
                  required
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  :type="showPassword ? 'text' : 'password'"
                ></v-text-field>
                <v-text-field
                  v-model="confirmPassword"
                  label="Confirm Password"
                  outlined
                  dense
                  required
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                  :type="showConfirmPassword ? 'text' : 'password'"
                ></v-text-field>

                <v-btn :loading="loading" type="submit" color="primary" class="mt-4">
                  <template v-if="!loading">Reset Password</template>
                  <template v-else>
                    <v-progress-circular indeterminate color="white" size="20"></v-progress-circular>
                  </template>
                </v-btn>
              </v-form>
            </template>
            <v-alert v-else type="error">
              Invalid or expired token. Please request a new password reset link.
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: "Home",
  components: {
    NavBar
  },
  data() {
    return {
      token: null,
      tokenValid: false,
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      loading: false 
    };
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.token = urlParams.get('token');

    if (this.token) {
      try {
        const response = await axios.get(axios.defaults.baseURL+"/reset-password", {
          params: { token: this.token },
        });

        if (response.status === 200) {
          this.tokenValid = true;
        }
      } catch (error) {
        console.error('Token validation error:', error);
      }
    }
  },
  methods: {
    async resetPassword() {
      try {
        this.loading = true; 
        const formData = new FormData();
        formData.append('token', this.token);
        formData.append('newPassword', this.password);

        const response = await axios.post(axios.defaults.baseURL+"/reset-password", formData);

        if (response.status === 200) {
          await this.showSuccessMessage();
          setTimeout(() => {
            this.$router.push('/login');
          }, 2000);
        }
      } catch (error) {
        console.error('Password reset error:', error);
      } finally {
        this.loading = false; 
      }
    },
    async showSuccessMessage() {
      await Swal.fire({
        icon: 'success',
        title: 'Password Reset',
        text: 'Your password has been reset successfully!',
        timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false
      });
    },
  },
};
</script>
