<template>
  <div>
    <NavBar />
    <v-form @submit.prevent="saveInv">
      <v-container style="display: flex;flex-direction: column;justify-content: center;">

        <!-- Πληροφορίες Πρόσκλησης -->
        <h1 style="font-size: 20px;font-weight: 600;">Πληροφορίες Πρόσκλησης</h1>
        <v-row>
          <v-col cols="4">
            <v-text-field outlined v-model="invitation.invName" required label="Όνομα Πρόσκλησης"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field outlined v-model="invitation.fromDate" required label="Έναρξη Πρόσκλησης"
              type="datetime-local"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field outlined v-model="invitation.toDate" required label="Λήξη Πρόσκλησης"
              type="datetime-local"></v-text-field>
          </v-col>
        </v-row>

        <!-- Πληροφορίες Θέσεων -->
        <h1 style="font-size: 20px;font-weight: 600;">Πληροφορίες Θέσεων</h1>
        <v-row>
          <v-col cols="6">
            <v-text-field outlined v-model="jobRole.jobRoleName" label="Όνομα Θέσης"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn style="margin-top: 10px;" color="secondary" @click="addJobRole">Προσθηκη Θεσης</v-btn>
          </v-col>
        </v-row>

        <!-- Display the heading only if there are job roles -->
        <v-row v-if="invitation.jobRoles.length > 0">
          <v-col cols="5"></v-col>
          <v-col cols="4">
            <h1 style="font-size: 20px;font-weight: 600;">Τρέχουσες Θέσεις</h1>
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>

        <!-- Display job roles -->
        <v-row v-for="(inv, index) in invitation.jobRoles" :key="index">
          <v-col cols="4"></v-col>
          <v-col cols="4">
            <v-text-field outlined v-model="inv.jobRoleName" label="Όνομα Θέσης" v-if="inv.jobRoleName !== null"
              :prepend-icon="inv.jobRoleName !== null ? 'mdi-close-circle' : undefined"
              @click:prepend="removeJobRole(index)"></v-text-field>
          </v-col>
          <v-col cols="4"></v-col>
        </v-row>

        <div class="mt-5" style="margin: auto;">
          <v-btn width="300px" type="submit" color="primary">Αποθηκευση</v-btn>
        </div>

      </v-container>
    </v-form>
  </div>
</template>
  
<script>
import NavBar from './NavBar.vue';
import axios from "axios"
import router from '../router';
import Swal from "sweetalert2";
import store from "../state/store";

export default {
  components: { NavBar },
  data() {
    return {
      categories: [],
      firstName: "",
      lastName: "",
      fatherName: "",
      persons: [],
      selectedCategory: [],
      invitation: {
        invName: "",
        fromDate: "",
        toDate: "",
        jobRoles: []
      },
      jobRole: {
        jobRoleName: null,
        jobRoleDescription: null,
        category: null
      }
    };
  },
  mounted() {
    // axios
    //   .get(axios.defaults.baseURL + "/api/categories")
    //   .then((response) => {
    //     this.categories = response.data;
    //     console.log(this.categories);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     alert("Failed to fetch categories");
    //   });
  },
  methods: {
    removeJobRole(index) {
      this.invitation.jobRoles.splice(index, 1);
    },
    addJobRole() {
      if (this.jobRole.jobRoleName != null) {
        this.invitation.jobRoles.push({
          jobRoleName: this.jobRole.jobRoleName,

        });
        this.jobRole.jobRoleName = null;
      }
    },
    saveInv() {
      if (this.invitation.invName != null && this.invitation.invName != '' &&
        this.invitation.fromDate != null && this.invitation.fromDate != '' &&
        this.invitation.toDate != null && this.invitation.toDate != '' &&
        this.invitation.jobRoles.length > 0) {

        axios.defaults.headers.common["Authorization"] = "Bearer " + store.state.user.token;
        axios
          .post(axios.defaults.baseURL + "/invitation/addInviteAndSave", this.invitation)
          .then(async (response) => {            
            if (response.status == 200) {
              await Swal.fire({
                icon: "success",
                title: "Αποθηκεύτηκε Επιτυχώς",
                text: "Η Πρόσκληση αποθηκεύτηκε επιτυχώς",
                timer: 2000,
                timerProgressBar: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false
              });
              router.push("/invOpenList");
            } else {
              await Swal.fire({
                icon: "error",
                title: "Αποθηκεύτηκε Ανεπιτυχώς",
                text: "Υπήρξε κάποιο πρόβλημα",
                timer: 2000,
                timerProgressBar: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false
              });
            }
          })
          .catch(async (error) => {
            console.error(error);
            await Swal.fire({
              icon: "error",
              title: "Αποθηκεύτηκε Ανεπιτυχώς",
              text: "Η Πρόσκληση αποθηκεύτηκε ανεπιτυχώς",
              timer: 2000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showConfirmButton: false
            });
          });
      }
    }
  },
};
</script>
<style scoped>
.container {
  width: 90%;
  margin-top: 1%;
  margin-bottom: 9%;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5%;
  background-color: #000000 !important;
  color: #ffffff;
  text-align: right !important;
}
</style>
