<template>
  <div class="background-image">
    <div v-if="isFetching">
    </div>
    <NavBar />

    <v-form @submit.prevent="saveEditSubmission" class="mt-5" ref="form" v-model="valid">
      <h1
        style="font-weight: 600;font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; text-align: center;padding-bottom: 20px;">
        Αίτηση για : {{ this.$store.state.invitation.selectedInvitation.invName }}</h1>
      <v-row justify="center" align="center">
        <v-col cols="2" class="hide-on-small"></v-col>
      </v-row>

      <v-row justify="center" align="center" style="padding-top: 15px;">
        <v-col cols="2" class="hide-on-small"></v-col>
        <v-col cols="12" sm="4" md="4" class="show-on-small">                    
          <v-file-input :rules="fileRules" v-model="submission.actualFile" accept=".zip" type="file"
            prepend-icon="mdi-file-plus" name="Username"
            label="Παρακαλώ ανεβάστε ένα zip αρχείο με όλα σας τα έγγραφα" 
            outlined dense @change="handleFileChange"></v-file-input>

          <div v-if="this.submission.underFileName != null"
            style="display: flex;margin-top: -25px;margin-left: 35px; flex-direction: row;">
            <div>Already uploaded </div> <button type="button" class="fileButtonDownload"
              @click="downloadUnderfile()">{{
                this.submission.underFileName }}</button>
            <div>.</div>
          </div>

        </v-col>
        <v-col cols="12" sm="4" md="4" class="show-on-small">
          <v-select v-model="submission.jobRoles" return-object required multiple prepend-icon="mdi-briefcase"
            style="padding-bottom: 10px;" label="Θέση *(required)" :items="jobRoles" item-text="jobRoleName"
            item-value="jobRoleId" :rules="positionRules" outlined dense></v-select>
        </v-col>
        <v-col cols="2" class="hide-on-small"></v-col>
      </v-row>

      <i><h4 align="center" >Παρακαλώ τοποθετήστε όλα σας τα αρχεία σε ένα .zip αρχείο</h4></i>

      <v-row justify="center" align="center" style="padding-top: 45px;">
        <div>
          <v-btn style="min-width:fit-content; width: 170px; color: white; margin-bottom: 100px;" @click="goBack"
            color="blue" large class="margin rounded-pill">Πισω</v-btn>
          <v-btn style="min-width:fit-content; width: 170px; color: white; margin-bottom: 100px;" type="submit"
            :disabled="!valid" color="blue" large class="margin rounded-pill" :loading="loadingButtonSaveSubmission">Αποθηκευση</v-btn>
        </div>
      </v-row>>
    </v-form>
  </div>
</template>

<script>


import NavBar from "./NavBar.vue";
import Swal from "sweetalert2";
import axios from "axios";
import router from "../router";
import { mapActions, mapGetters } from "vuex";
// import store from "../state/store";

export default {
  name: "MySubmission",
  components: {
    NavBar,
  },

  data: () => ({
    loadingButtonSaveSubmission: false,
    valid: false,
    positionRules: [
      v => (v && v.length >= 1) || 'Πρέπει να επιλέξετε τουλάχιστον μία θέση'
    ],
    fileRules: [
        v => (v && v.size > 0) || 'Το ανέβασμα του zip αρχείου είναι υποχρεωτικό'
      ],
    querySubmission: {
      userId: "",
      invitationId: ""
    },
    submission: {
      invitation: {
        invitationId: null
      },
      user: {
        userId: null
      },
    },    
    isFetching: false,
    jobRole: null,
  }),


  async mounted() {

    this.querySubmission.invitationId = this.$store.state.invitation.selectedInvitation.invitationId;
    this.querySubmission.userId = this.$store.state.user.userObj.userId;
    this.submission = await this.getSubmissionByUserIdAndInvitationIdAction(this.querySubmission)

    if (this.submission == "") {
      this.submission = {};
      this.submission.user = {};
      this.submission.user.userId = this.$store.state.user.userObj.userId;
      this.submission.user.userName = this.$store.state.user.userObj.userName;
      this.submission.user.userEmail = this.$store.state.user.userObj.userEmail;
      this.submission.user.userFirstName = this.$store.state.user.userObj.userFirstName;
      this.submission.user.userLastName = this.$store.state.user.userObj.userLastName;
      this.submission.invitation = {};
      this.submission.invitation.invitationId = this.$store.state.invitation.selectedInvitation.invitationId;  
      this.submission.invitation.invName = this.$store.state.invitation.selectedInvitation.invName;  
    }
  },

  computed: {
    ...mapGetters('invitation', ['getSelectedInvitationJobRoles']),
    ...mapGetters('submission', ['getSelectedSubmission']),
    jobRoles() {
      return this.getSelectedInvitationJobRoles;
    },
  },

  methods: {

    ...mapActions('submission', ['addUpdateSubmissionAction', 'getSubmissionByUserIdAndInvitationIdAction', 'uploadFileAction']),

    handleFileChange(file) {        
      this.submission.actualFile = file
      this.submission.underFileName = file.name
    },

    goBack() {
      router.push('/InvOpenList');
    },

    async downloadUnderfile() {
      await axios
        .get("/user/downloadUnderFile/" + this.submission.id, {
          responseType: "blob"
        })
        .then(async ({ data, status }) => {
          if (status === 200) {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.submission.underFileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            await this.showAlertMessage("success", "Download complete!", "File download successfully.");
          }
        })
        .catch(async (error) => {
          await this.showAlertMessage("error", "Download Failed!", error);
        });
    },

    async saveEditSubmission() {
      this.loadingButtonSaveSubmission = true;
      await this.addUpdateSubmissionAction(this.submission);  
      this.submission.subId = this.$store.state.submission.selectedSubmissionId
      await this.uploadFileAction(this.submission);
      this.loadingButtonSaveSubmission = false;
    },

    async showAlertMessage(icon, title, message) {
      await Swal.fire({
        icon: icon,
        title: title,
        text: message,
        timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false
      });
    }
  },
};
</script>

<style scoped>
.v-card__actions {
  justify-content: space-between;
  align-items: center;
}

.fileButtonDownload {
  color: rgb(0, 153, 255);
  cursor: pointer;
  /* z-index: 100; */
  padding-bottom: 10px;
  padding-left: 5px;
  z-index: 100;
}

.fieldsTitle {
  position: absolute;
  font-weight: 600;
  font-size: 20px;
  margin-top: -40px;
  margin-left: 32px;
  text-wrap: nowrap;
}

.fileButtonDownload:hover {
  text-decoration: underline;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5%;
  background-color: #000000 !important;
  color: #ffffff;
  text-align: right !important;
}

@media screen and (max-width: 600px) {
  .margin {
    margin-bottom: 15vw !important;
  }

  .show-on-small {
    display: block;
  }

  .show-on-small .v-input {
    width: 100%;
  }

  .hide-on-small {
    display: none;
  }
}

/* Media query for mobile screens (up to 4120px) */
@media screen and (max-width: 4120px) {
  .margin {
    margin-bottom: 15vw !important;
  }
}

.text-input {
  width: 100%;
  margin-bottom: 20px;
}
</style>
