import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export default {
    namespaced: true,
    state: {
        isLoggedIn: false,        
        selectedInvitation: {
            jobRoles: []
        },
    },

    mutations: {
        setSelectedInvitationMutation(state, item) {
            state.selectedInvitation = item;
        },        
    },

    actions: {

        setSelectedInvitationAction: ({ commit }, item) => {
            return new Promise((resolve) => {
                commit("setSelectedInvitationMutation", item);
                resolve(true);
            });
        },

    },

    getters: {
        getSelectedInvitationJobRoles(state) {
            return state.selectedInvitation.jobRoles;
          },
    }
}
