<template>
    <v-container>
      <NavBar></NavBar>
      <v-row class="text-center">
        <v-col class="mb-4">
          <hr><br>
         
          <h2>Access Denied</h2>
          <p>You do not have permission to access this page.</p>
        
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import NavBar from "@/components/NavBar.vue";
  
  export default {
    name: "AccessDenied",
    components: {
      NavBar
    }
  };
  </script>
  