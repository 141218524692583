<template>
    <footer class="bg-light text-center text-lg-start">
      <div class="footerContainer">
        <div class="text-center"><a href="http://lmids.sse.gr/" target="_blank">2019-2024 © Lab MIDS </a></div><div class="logofooter"><v-img :src="require('../assets/LogoSSE.png')" style="width: 20px;" /></div><div> <a href="https://sse.army.gr" target="_blank"> Hellenic Army Academy</a></div>
        <!-- <div class="logofooter"><v-img :src="require('../assets/LogoSSE.png')" style="width: 20px;" /></div> -->
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'Footer',
  };
  </script>

<style>

.footerContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.logofooter{
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
  