<template>
  <div>
    <NavBar />
    <v-form @submit.prevent="saveInv">
      <v-container style="display: flex;flex-direction: column;justify-content: center;flex-wrap: wrap;">

        <h1 style="font-size: 20px;font-weight: 600;">Πληροφορίες Πρόσκλησης</h1>
        <v-row>
          <v-col cols="4">
            <v-text-field outlined v-model="invitation.invName" :disabled="statusPage != 'edit'" required
              label="Όνομα Πρόσκλησης"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field outlined v-model="invitation.fromDate" :disabled="statusPage != 'edit'" required
              label="Έναρξη Πρόσκλησης" type="datetime-local"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field outlined v-model="invitation.toDate" :disabled="statusPage != 'edit'" required
              label="Λήξη Πρόσκλησης" type="datetime-local"></v-text-field>
          </v-col>
        </v-row>

        <h1 v-if="statusPage === 'edit'" style="font-size: 20px;font-weight: 600;">Πληροφορίες Θέσεων</h1>
        <v-row v-if="statusPage === 'edit'">
          <v-col cols="6">
            <v-text-field outlined v-model="jobRole.jobRoleName" label="Όνομα Θέσης"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn style="margin-top: 10px;" color="secondary" @click="addJobRole">Προσθηκη Θεσης</v-btn>
          </v-col>
        </v-row>
        <div style="width: 100%;display: flex;gap: 20px;">
          <div v-if="invitation.jobRoles.length > 0">
            <h1 style="font-size: 20px;font-weight: 600;padding-bottom: 20px;">Τρέχουσες Θέσεις</h1>


            <!-- Display job roles -->
            <div v-for="(inv, index) in invitation.jobRoles" :key="index">
              <v-text-field style="max-width: 300px;" outlined disabled v-model="inv.jobRoleName"
                label="Όνομα Θέσης"></v-text-field>
            </div>
          </div>
          <div style="width: 100%;">
            <h1 style="font-size: 20px;font-weight: 600;padding-bottom: 20px;">Υποψήφιοι</h1>
            <v-card style="width: 100%;">
              <v-card-title>
              </v-card-title>
              <v-data-table :headers="headers" :items="candidates" :items-per-page="itemsPerPage" :page.sync="page"
                @update:page="updatePage" @update:items-per-page="updateItemsPerPage" :server-items-length="totalCandidates">
                <template v-slot:item.jobRoles="{ item }">
                  <div v-for="role in item.jobRoles" :key="role.id">
                    {{ role.jobRoleName }}
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </div>

        <div style="display: flex;justify-content: center;align-items: center; gap: 50px;">
          <div class="mt-5">
            <v-btn width="300px" @click="goBack" color="primary">
              Πισω
            </v-btn>
          </div>
          <div v-if="statusPage === 'edit'" class="mt-5">
            <v-btn width="300px" style="color: white;" type="submit" color="#6de195">
              Αποθηκευση
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import NavBar from './NavBar.vue';
import axios from "axios"
import router from '../router';
import Swal from "sweetalert2";
import store from '../state/store';
export default {
  components: { NavBar },
  data() {
    return {
      categories: [],
      firstName: "",
      lastName: "",
      fatherName: "",
      statusPage: null,
      invSelectedID: null,
      persons: [],
      selectedCategory: [],
      invitation: {
        invName: "",
        fromDate: "",
        toDate: "",
        jobRoles: []
      },
      jobRole: {
        jobRoleName: null,
        jobRoleDescription: null,
        category: null
      },
      headers: [
        { text: "Id Invitation", value: "invitation.invitationId" },
        { text: "Id Χρήστη", value: "user.userId" },
        { text: "Επώνυμο", value: "user.userLastName" },
        { text: "Όνομα", value: "user.userFirstName" },
        { text: "Διεύθυνση Ηλεκτρονικού Ταχυδρομείου", value: "user.userEmail", sortable: false },
        { text: "Θέση", value: "jobRoles", sortable: false },
      ],
      candidates: [],
      totalCandidates: 0,
      page: 1,
      itemsPerPage: 10,
    };
  },

  mounted() {
    axios.defaults.headers.common["Authorization"] = "Bearer " + store.state.user.token;
    this.invSelectedID = this.$route.params.id;
    this.statusPage = this.$route.params.status;
    this.invitation = this.$store.state.person.selectedInv
    this.getSubmissionsByInvitationIdPaged();
  },

  computed: {
    adjustedItemsPerPage() {
      return this.itemsPerPage === -1 ? 1000 : this.itemsPerPage;
    }
  },

  methods: {
    goBack() {
      router.push('/InvOpenList');
    },
    addJobRole() {
      if (this.jobRole.jobRoleName != null) {
        this.invitation.jobRoles.push({
          jobRoleName: this.jobRole.jobRoleName,
        });
        this.jobRole.jobRoleName = null;
      }
    },

    saveInv() {
      axios
        .post(axios.defaults.baseURL + "/invitation/addInviteAndSave", this.invitation)
        .then(async (response) => {
          console.log(response.data);
          if (response.status === 200) {
            await Swal.fire({
              icon: "success",
              title: "Αποθηκεύτηκε Επιτυχώς",
              text: "Η Πρόσκληση αποθηκεύτηκε επιτυχώς",
              timer: 2000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showConfirmButton: false
            });
            router.push("/InvOpenList");
          } else {
            await Swal.fire({
              icon: "error",
              title: "Αποθηκεύτηκε Ανεπιτυχώς",
              text: "Η Πρόσκληση αποθηκεύτηκε ανεπιτυχώς",
              timer: 2000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showConfirmButton: false
            });
          }
        })
        .catch(async (error) => {
          console.error(error);
          await Swal.fire({
            icon: "error",
            title: "Αποθηκεύτηκε Ανεπιτυχώς",
            text: "Η Πρόσκληση αποθηκεύτηκε ανεπιτυχώς",
            timer: 2000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false
          });
        });
    },

    updatePage(newPage) {
      this.page = newPage;
      this.getSubmissionsByInvitationIdPaged();
    },

    updateItemsPerPage(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      this.getSubmissionsByInvitationIdPaged();
    },


    async getSubmissionsByInvitationIdPaged() {
      axios.defaults.headers.common["Authorization"] = "Bearer " + store.state.user.token;
      axios
        .get(
          axios.defaults.baseURL +
          `/submission/invitation/` + this.invitation.invitationId, {
          params: {
            page: this.page - 1,
            size: this.adjustedItemsPerPage
          }
        })
        .then((response) => {
          console.log(response);
          this.candidates = response.data.content;
          this.totalCandidates = response.data.totalElements;
        })
        .catch((error) => {
          console.error(error);
        });
    },

  },
};
</script>
<style scoped>
.container {
  width: 90%;
  margin-top: 1%;
  margin-bottom: 9%;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5%;
  background-color: #000000 !important;
  color: #ffffff;
  text-align: right !important;
}
</style>
