<template>
  <div class="">
    <v-app-bar class="navTest" app height="90"
      :style="isHomePage ? 'box-shadow: 0px 2px 10px 0.5px rgba(0, 0, 0, 0.2);' : 'box-shadow: 0px 2px 10px 0.5px rgba(0, 0, 0, 0);'"
      dark>
      <v-toolbar-items>
        <v-img @click="redirectToHome" class="ma-2 pr-5" :src="require('../assets/SSE_logo.png')"
          transition="scale-transition" style="width: 65px; cursor: pointer;" contain />
        <div style="color: black;text-align: center;margin: auto;padding-left: 5px;font-weight: 600;">Διαχείριση Αιτήσεων
          ΣΣΕ</div>
      </v-toolbar-items>
      <v-spacer />

      <!-- <v-toolbar-items class="" v-if="loggedIn && isAdmin">
        <v-btn v-for="link in navLinks" :key="link.title" @click="forward(link.title)" plain
          class="secondary--text hidden-md-and-down" :ripple="false" text>
          {{ link.title }}
        </v-btn>
      </v-toolbar-items> -->


      <v-toolbar-items class="" v-if="loggedIn">
        <v-btn plain class="secondary--text" :ripple="false" text @click="forward('personaldata')">ΠΡΟΣΩΠΙΚΑ ΣΤΟΙΧΕΙΑ</v-btn>         
        <v-btn plain class="secondary--text" :ripple="false" text @click="forward('InvOpenList')">ΠΡΟΣΚΛΗΣΕΙΣ</v-btn>
        <v-btn plain class="secondary--text" :ripple="false" text @click="forward('logout')">ΑΠΟΣΥΝΔΕΣΗ</v-btn>
        <!-- <v-btn v-for="link in loggedInMemberLinks" :key="link.title" @click="forward(link.title)" plain :ripple="false" text>
          {{ link.title }}
        </v-btn>         -->
      </v-toolbar-items>

      <!-- <v-menu :close-on-content-click="closeOnContentClick" :offset-y="true">
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon
            v-bind="attrs"
            v-on="on"
            class="hidden-lg-and-up"
            v-if="!path"
            :color="!isHomePage ? 'blue' : 'grey'"
          >
          </v-app-bar-nav-icon>
        </template>
        <v-list dense>
          <v-list-item v-for="(link, index) in sideMenuLinks" :key="index" @click="forward(link.title)">
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="(link, index) in loggedInMemberLinks" :key="index" @click="forward(link.title)">
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-app-bar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "navbar",
  data: () => ({
    closeOnContentClick: false,
    selectedLang: null,
    adminOrGreek: false,
    navLinks: [],
    excelLinks: [],
    greekData: {},
    formLinks: [],
    dataLinks: [],
    loggedInMemberLinks: [],
    false: false,
    dialog: false,
    path: false,
    sideMenuLinks: []
  }),
  mounted() {
    // this.syncFun();
  },
  watch:{
    $route: {
      handler(to, from){
        // this.syncFun();
        console.log(to,from);
      },
      immediate: true // This will trigger the watcher immediately upon component creation
    }
  },
  methods: {
    ...mapActions("user", ["destroyToken"]),

    redirectToHome() {
      this.$router.push("/").catch(() => { });
    },
    logout() {
      {
      
        this.destroyToken().then(() => {
          this.$router.push("/login").catch(() => { });
        });
      }
    },



    forward(pageToMove) {
      if (pageToMove === this.$t("about")) {
        this.$router.push("/about").catch(() => { });
      }
      else if (pageToMove === this.$t("users")) {
        this.$router.push("/list").catch(() => { });
      }
      else if (pageToMove === 'InvOpenList') {
        this.$router.push("/InvOpenList").catch(() => {});
      }
      else if (pageToMove === 'logout') {
      this.destroyToken().then(() => {
        this.$router.push("/login").catch(() => {});
      });
      } else if (pageToMove === this.$t("login")) {
        this.$router.push("/login").catch(() => { });
      } 
      else if (pageToMove === 'personaldata') {
        this.$router.push("/personaldata").catch(() => { });
      }
    },
  },
  computed: {
    ...mapGetters("user", ["getUserRole", "loggedIn"]),
    isHomePage() {
      return document.URL.includes("about");
    },
    isAdmin() {      
      return this.getUserRole.includes('admin');
    }
  },
  beforeMount() {
    if (this.$i18n.locale == "en") {
      this.selectedLang = 0;
    } else {
      this.selectedLang = 1;
    }
    if (window.location.href.match("landingPage")) {
      this.path = true;
    }
    if (this.loggedIn) {
      
      this.navLinks = [        
        { title: this.$t("users") }];
      if(this.isAdmin){
        this.sideMenuLinks = [
          { title: this.$t("users") },{ title: this.$t("Invitations") }];
      }
      this.loggedInMemberLinks = [{ title: "Αποσυνδεση" }];
    }
  },
};
</script>

<style scoped>
.listItem {
  padding-left: 16px !important;
  margin: 0 16px !important;
}

.navTest {
  background: linear-gradient(0deg, rgb(255, 255, 255), rgb(239, 239, 239) 35%, rgb(225, 225, 225) 100%) !important;
}

.v-menu__content {
  background: white;
}

.apexcharts-toolbar {
  z-index: 0;
}

.v-btn--active .v-btn__content {
  color: whitesmoke;
}

.btnContainer {
  align-self: center;
}

.paddingLeft {
  padding-left: 1rem;
}

.listItem {
  margin: 1rem;
}
</style>
