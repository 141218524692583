<template>
  <div class="background-image">
    <NavBar />
    <v-container fluid class="bg-black py-10">
      <div>
        <div style="text-align: center;margin-left: 20px;" primary-title>
          <h4 class="text-uppercase font-weight-bold">Δημιουργια Λογαριασμου</h4>
        </div>
        <v-card-text class="px-8 py-4">
          <v-form @submit.prevent="register">
            <v-text-field v-model="userName" prepend-icon="mdi-account" name="Username" :label="$t('usernameRegister')"
              outlined dense></v-text-field>
            <v-row justify="center" align="center">
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="FName" start-append prepend-icon="mdi-account" color="#00695C" outlined
                  :label="$t('firstname')" dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="LName" start-append prepend-icon="mdi-account" color="#00695C" outlined
                  :label="$t('lastname')" dense></v-text-field>
              </v-col>
            </v-row>

            <v-text-field v-model="Email" start-append prepend-icon="mdi-email" name="Email" :label="$t('Email')"
              type="email" autocomplete="on" outlined dense></v-text-field>

            <v-text-field v-model="mobilePhone" start-append prepend-icon="mdi-phone" name="Mobile" label="Κινητό"
              type="email" autocomplete="on" outlined dense></v-text-field>

            <v-text-field v-model="userPassword" start-append prepend-icon="mdi-lock" name="Password"
              :label="$t('password')" :type="showPassword ? 'text' : 'password'" autocomplete="on" outlined dense
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"></v-text-field>
            <v-text-field v-model="userPasswordRe" start-append prepend-icon="mdi-lock" name="Password"
              :label="$t('repassword')" :type="showRePassword ? 'text' : 'password'" autocomplete="on" outlined dense
              :append-icon="showRePassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showRePassword = !showRePassword"></v-text-field>


            <div class=""
              style="width: 100%; display: flex; justify-content: center; align-items: center;margin-left: 6px;">
              <v-btn :loading="loadingLogin" style="width: 30%; color: white;" type="submit" color="blue" large
                class="rounded-pill" :disabled="!isFormValid">Εγγραφή</v-btn>
            </div>
          </v-form>

          <div class="text-center mt-4">
            <router-link to="/login">Έχετε λογαριασμο; Σύνδεση</router-link>
          </div>

        </v-card-text>
      </div>
    </v-container>
  </div>
</template>

<script>


import NavBar from "./NavBar.vue";
import Swal from "sweetalert2";

export default {
  name: "Signup",
  components: {

    NavBar,
  },

  data: () => ({
    loadingLogin: false,
    showPassword: false,
    showRePassword: false,
    userName: "",
    userPassword: "",
    userPasswordRe: "",
    mobilePhone: "",
    Email: "",
    FName: "",
    LName: "",
  }),

  computed: {
    isFormValid() {
      return this.userName && this.userPassword && this.userPasswordRe && this.Email && this.FName && this.LName;
    }
  },

  methods: {
    async register() {
      this.loadingLogin = true;
      if (this.userName.length > 0 && this.userPassword.length > 0 && this.userPasswordRe.length > 0 && this.Email.length > 0 && this.FName.length > 0 && this.LName.length > 0) {
        if (this.userPassword === this.userPasswordRe) {
          this.$store.dispatch("user/registerUser", {
            userName: this.userName,
            userPassword: this.userPassword,
            userEmail: this.Email,
            mobilePhone: this.mobilePhone,
            userFirstName: this.FName,
            userLastName: this.LName,
          }).then(() => {
            this.loadingLogin = false;
          }).catch(async (error) => {
            console.error(error);
            Swal.fire({
              icon: "info",
              title: "Προσοχή!",
              text: error.response.data.message,
              timer: 2000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showConfirmButton: false
            });
            // await this.showAlertMessage("error", "Παρουσιάστηκε προβλημα", "Παρακαλώ επικοινωνήστε με τον διαχειριστή του συστήματος");
            this.loadingLogin = false;
          });
        } else {
          // swal("Password not match, please try again!");
          // await this.showAlertMessage("info", "Registration failed!", "Password not match, please try again!");
          Swal.fire({
            icon: "info",
            title: "Προσοχή!",
            text: "Παρουσιάστηκε κάποιο πρόβλημα, επικοινωνήστε με τον διαχειριστή του συστήματος",
            timer: 2000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false
          });
          this.loadingLogin = false;
        }
      } else {
        // swal("Invalid fields, please try again!");
        // await this.showAlertMessage("info", "Registration failed!", "Invalid fields, please try again!");
        Swal.fire({
          icon: "info",
          title: "Προσοχή!",
          text: "Παρουσιάστηκε κάποιο πρόβλημα, επικοινωνήστε με τον διαχειριστή του συστήματος",
          timer: 2000,
          timerProgressBar: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false
        });
        this.loadingLogin = false;
      }
    },
    async showAlertMessage(icon, title, message) {
      await Swal.fire({
        icon: icon,
        title: title,
        text: message,
        timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false
      });
    }
  },
};
</script>

<style scoped>
.v-card__actions {
  justify-content: space-between;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5%;
  background-color: #000000 !important;
  color: #ffffff;
  text-align: right !important;
}

.text-input {
  width: 100%;
  margin-bottom: 20px;
}
</style>
