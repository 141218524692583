<template>
    <div>
        <NavBar />
        <div class="container">
            <div class="row">
                <!-- <div class="col-sm-6">
                    <button @click="redirectToAdd" class="btn btn-primary">
                        {{ $t("Add") }}
                    </button>
                </div> -->
                <div class="col-sm-6">
                    <input type="text" v-model="searchQuery" placeholder="Search" class="form-control"
                        @input="searchPersons(1, itemsPerPage)" />
                </div>
            </div>
            <div class="col-sm-12">
                <!-- <v-layout align-center justify-center>
                    <v-progress-circular v-if="loading" indeterminate color="primary"
                        style="margin-top: 5%"></v-progress-circular>
                </v-layout> -->
                <v-data-table :headers="headers" :loading="loading" :items="items" item-key="id"
                    :server-items-length="totalItems" @pagination="onPagination"
                    :footer-props="{ itemsPerPageOptions: [5, 10, 20, 50, 100] }">
                    <template v-slot:item.actionEdit="{ item }">
                        <div class="actionButs">
                            <v-btn color="success" small @click="editViewUser(item)">Προβολη/Επεξεργασία</v-btn>
                            <v-btn color="error" small @click="Delete(item)">Διαγραφη</v-btn>
                        </div>
                    </template>
                </v-data-table>
            </div>
        </div>
    </div>
</template>
  
<script>
import NavBar from "./NavBar.vue";
import axios from "axios";
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import store from "../state/store";
import router from "../router";

export default {
    name: "List",
    components: { NavBar },
    data() {
        return {
            searchQuery: "",
            personSearch: [],
            items: [],
            totalPages: 0,
            rowsPerPageItems: [10, 20, 50],
            filteredPersons1: [],
            displayedItems: [],
            currentPage: 1,
            itemsPerPage: 10,
            perPage: 10,
            pagination: {
                page: 1,
                rowsPerPage: 10,
            },
            search: "",
            showAddForm: false,
            showEditForm: false,
            selectedItem: null,
            firstName: "",
            lastName: "",
            fatherName: "",
            loading: true,
            persons: [],
            selectedCategory: null,
            categoryName: "",
            editor: "",
            bio: "",
            totalItems: 0,
            origin: "",
            property: "",
            bibliography: "",
            searchItems: {
                firstName: "",
                lastName: "",
            },
            headers: [
                { text: "Επώνυμο", value: "userLastName" },
                { text: "Όνομα", value: "userFirstName" },
                { text: "", value: "actionView", sortable: false },
                { text: "Δράσεις", value: "actionEdit", sortable: false,align:"center" },
                { text: "", value: "actionDelete", sortable: false },
            ],
        };
    },
    computed: {
        //     filteredPersons() {
        //       const searchParts = this.searchQuery.trim().toLowerCase().split(" ");
        //       return this.items.filter((person) => {
        //         const firstName = person.firstName
        //           ? person.firstName.toLowerCase()
        //           : "";
        //         const lastName = person.lastName ? person.lastName.toLowerCase() : "";
        //         return searchParts.every((searchPart) => {
        //           return (
        //             firstName.includes(searchPart) ||
        //             lastName.includes(searchPart) ||
        //             (firstName + " " + lastName).includes(searchPart)
        //           );
        //         });
        //       });
        //     },
    },
    // created() {
    //      this.getData();
    //  },
    mounted() { 
    },
    methods: {
        ...mapActions("person", ["showDetails", "editDetails"]),
        
        async onPagination({ page, itemsPerPage = 10 }) {
            this.loading = true;
            console.log(page, itemsPerPage, this.searchQuery);
            if (this.searchQuery) {
                this.searchPersons(page, itemsPerPage);
            } else {
                this.getPersonsPaged(page,itemsPerPage)
            }
        },
        async editViewUser(item){
            await this.$store.dispatch("user/viewUser",item).then(() => {
                router.push("/viewEdit");
            }).catch((error) => {
                console.error(error);
            });
        },
        async searchPersons(page, itemsPerPage) {
            try {
                const response = await axios.get(
                    axios.defaults.baseURL +
                    `/user/users/search?query=${this.searchQuery}&page=${page - 1}&pageSize=${itemsPerPage}`
                );
                this.totalPages = response.data.totalPages;
                this.totalItems = response.data.totalElements;
                this.items = response.data.content;
                this.loading = false;
                console.log(this.totalItems);
            } catch (error) {
                console.log(error);
            }
        },

        async getPersonsPaged(page, itemsPerPage) {
            axios.defaults.headers.common["Authorization"] ="Bearer " + store.state.user.token;
            axios
                .get(
                    axios.defaults.baseURL +
                    `/user/usersPaged?page=${page - 1}&size=${itemsPerPage}`
                )
                .then((response) => {
                    this.items = response.data.content;
                    this.totalPages = response.data.totalPages;
                    this.totalItems = response.data.totalElements;
                    this.loading = false;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        show(item) {
            this.showDetails(item);
        },
        edit(item) {
            this.editDetails(item);
        },
        redirectToAdd() {
            this.$router.push("/add");
        },
        async Delete(item) {
            const confirmed = await Swal.fire({
                title: `Είσαι σίγουρος ότι θέλεις να διαγράψεις: ${item.userFirstName} ${item.userLastName}?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ναί, διαγραφή!",
                cancelButtonText: "Όχι",
                reverseButtons: true,
            });

            if (confirmed.isConfirmed) {
                try {
                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + store.state.user.token;
                    await axios.post(axios.defaults.baseURL + "/user/deleteUser",item);
                    await this.showAlertMessage("success", "Success!", "Επιτυχής Διαγραφή Χρήστη");
                    this.getPersonsPaged(1,10)
                } catch (error) {
                    console.log(error);
                }
            }
        },
        showAlertMessage(icon, title, message) {
        Swal.fire({
          icon: icon,
          title: title,
          text: message,
          timer: 2000,
          timerProgressBar: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false
        });
      }
    },
};
</script>
  
<style scoped>
footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5%;
    background-color: #000000 !important;
    color: #ffffff;
    text-align: right !important;
}


.actionButs{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

.v-data-table__wrapper {
    overflow-x: auto;
}

.v-data-table__wrapper table {
    width: 100%;
    white-space: nowrap;
}

.v-data-table__wrapper td {
    padding: 16px;
    text-overflow: ellipsis;
    vertical-align: middle;
}

@media (max-width: 767px) {
    .container {
        padding: 10px;
    }

    .v-data-table__wrapper th {
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;
    }

    .v-data-table__wrapper td {
        font-size: 14px;
        white-space: nowrap;
    }
}
</style>