const countriesData = ['ΕΛΛΑΔΑ',
    'ΑΦΓΑΝΙΣΤΑΝ',
    'ΑΛΒΑΝΙΑ',
    'ΑΛΓΕΡΙΑ',
    'ΑΜΕΡΙΚΑΝΙΚΗ ΣΑΜΟΑ',
    'ΑΝΔΟΡΡΑ',
    'ΑΝΓΚΟΛΑ',
    'ΑΝΓΚΟΥΙΛΑ',
    'ΑΝΤΑΡΚΤΙΚΗ',
    'ΑΝΤΙΓΚΟΥΑ ΚΑΙ ΜΠΑΡΜΠΟΥΝΤΑ',
    'ΑΡΓΕΝΤΙΝΗ',
    'ΑΡΜΕΝΙΑ',
    'ΑΡΟΥΜΠΑ',
    'ΑΥΣΤΡΑΛΙΑ',
    'ΑΥΣΤΡΙΑ',
    'ΑΖΕΡΜΠΑΪΤΖΑΝ',
    'ΜΠΑΧΑΜΕΣ',
    'ΜΠΑΧΡΕΙΝ',
    'ΜΠΑΝΓΚΛΑΝΤΕΣ',
    'ΜΠΑΡΜΠΑΝΤΟΣ',
    'ΛΕΥΚΟΡΩΣΙΑ',
    'ΒΕΛΓΙΟ',
    'ΜΠΕΛΙΖ',
    'ΜΠΕΝΙΝ',
    'ΒΕΡΜΟΥΔΕΣ',
    'ΜΠΟΥΤΑΝ',
    'ΒΟΛΙΒΙΑ',
    'ΒΟΣΝΙΑ-ΕΡΖΕΓΟΒΙΝΗ',
    'ΜΠΟΤΣΟΥΑΝΑ',
    'ΜΠΟΥΒΕ',
    'ΒΡΑΖΙΛΙΑ',
    'ΜΠΡΟΥΝΕΙ',
    'ΒΟΥΛΓΑΡΙΑ',
    'ΜΠΟΥΡΚΙΝΑ ΦΑΣΟ',
    'ΜΠΟΥΡΟΥΝΤΙ',
    'ΚΑΜΠΟΤΖΗ',
    'ΚΑΜΕΡΟΥΝ',
    'ΚΑΝΑΔΑΣ',
    'ΠΡΑΣΙΝΟ ΑΚΡΩΤΗΡΙ',
    'ΚΕΙΜΑΝ ΝΗΣΟΙ',
    'ΚΕΝΤΡΟΑΦΡΙΚΑΝΙΚΗ ΔΗΜΟΚΡΑΤΙΑ',
    'ΤΣΑΝΤ',
    'ΧΙΛΗ',
    'ΚΙΝΑ',
    'ΝΗΣΟΣ ΤΩΝ ΧΡΙΣΤΟΥΓΕΝΝΩΝ',
    'ΝΗΣΙΑ ΚΟΚΟΣ (KEELING)',
    'ΚΟΛΟΜΒΙΑ',
    'ΚΟΜΟΡΕΣ',
    'ΚΟΓΚΟ',
    'ΛΑΪΚΗ ΔΗΜΟΚΡΑΤΙΑ ΤΟΥ ΚΟΓΚΟ',
    'ΝΗΣΟΙ ΚΟΥΚ',
    'ΚΟΣΤΑ ΡΙΚΑ',
    'ΑΚΤΗ ΕΛΕΦΑΝΤΟΣΤΟΥ',
    'ΚΡΟΑΤΙΑ',
    'ΚΟΥΒΑ',
    'ΚΥΠΡΟΣ',
    'ΤΣΕΧΙΑ',
    'ΔΑΝΙΑ',
    'ΤΖΙΜΠΟΥΤΙ',
    'ΔΟΜΙΝΙΚΑ',
    'ΔΟΜΙΝΙΚΑΝΗ ΔΗΜΟΚΡΑΤΙΑ',
    'ΙΣΗΜΕΡΙΝΟΣ',
    'ΑΙΓΥΠΤΟΣ',
    'ΕΛ ΣΑΛΒΑΔΟΡ',
    'ΙΣΗΜΕΡΙΝΗ ΓΟΥΙΝΕΑ',
    'ΕΡΥΘΡΑΙΑ',
    'ΕΣΘΟΝΙΑ',
    'ΑΙΘΙΟΠΙΑ',
    'ΝΗΣΟΙ ΦΩΚΛΑΝΤ',
    'ΝΗΣΟΙ ΦΕΡΟΕΣ',
    'ΦΙΤΖΙ',
    'ΦΙΝΛΑΝΔΙΑ',
    'ΓΑΛΛΙΑ',
    'ΓΑΛΛΙΚΗ ΓΟΥΙΑΝΑ',
    'ΓΑΛΛΙΚΗ ΠΟΛΥΝΗΣΙΑ',
    'ΓΚΑΜΠΟΝ',
    'ΓΚΑΜΠΙΑ',
    'ΓΕΩΡΓΙΑ',
    'ΓΕΡΜΑΝΙΑ',
    'ΓΚΑΝΑ',
    'ΓΙΒΡΑΛΤΑΡ',
    'ΓΡΟΙΛΑΝΔΙΑ',
    'ΓΡΕΝΑΔΑ',
    'ΓΟΥΑΔΕΛΟΥΠΗ',
    'ΓΚΟΥΑΜ',
    'ΓΟΥΑΤΕΜΑΛΑ',
    'ΓΟΥΙΝΕΑ',
    'ΓΟΥΙΝΕΑ-ΜΠΙΣΣΑΟΥ',
    'ΓΟΥΙΑΝΑ',
    'ΑΪΤΗ',
    'ΝΗΣΟΙ ΧΕΡΝΤ ΚΑΙ ΜΑΚΝΤΟΝΑΛΝΤ',
    'ΒΑΤΙΚΑΝΟ',
    'ΟΝΔΟΥΡΑ',
    'ΧΟΝΓΚ ΚΟΝΓΚ',
    'ΟΥΓΓΑΡΙΑ',
    'ΙΣΛΑΝΔΙΑ',
    'ΙΝΔΙΑ',
    'ΙΝΔΟΝΗΣΙΑ',
    'ΙΡΑΝ',
    'ΙΡΑΚ',
    'ΙΡΛΑΝΔΙΑ',
    'ΙΣΡΑΗΛ',
    'ΙΤΑΛΙΑ',
    'ΤΖΑΜΑΙΚΑ',
    'ΙΑΠΩΝΙΑ',
    'ΙΟΡΔΑΝΙΑ',
    'ΚΑΖΑΚΣΤΑΝ',
    'ΚΕΝΥΑ',
    'ΚΙΡΙΜΠΑΤΙ',
    'ΒΟΡΕΙΑ ΚΟΡΕΑ',
    'ΝΟΤΙΑ ΚΟΡΕΑ',
    'ΚΟΥΒΕΙΤ',
    'ΚΙΡΓΙΖΙΑ',
    'ΛΑΟΣ',
    'ΛΕΤΤΟΝΙΑ',
    'ΛΙΒΑΝΟΣ',
    'ΛΕΣΟΤΟ',
    'ΛΙΒΕΡΙΑ',
    'ΛΙΒΥΗ',
    'ΛΙΧΤΕΝΣΤΑΪΝ',
    'ΛΙΘΟΥΑΝΙΑ',
    'ΛΟΥΞΕΜΒΟΥΡΓΟ',
    'ΜΑΚΑΟ',
    'ΠΓΔΜ',
    'ΜΑΔΑΓΑΣΚΑΡΗ',
    'ΜΑΛΑΟΥΙ',
    'ΜΑΛΑΙΣΙΑ',
    'ΜΑΛΔΙΒΕΣ',
    'ΜΑΛΙ',
    'ΜΑΛΤΑ',
    'ΝΗΣΟΙ ΜΑΡΣΑΛ',
    'ΜΑΡΤΙΝΙΚΑ',
    'ΜΑΥΡΙΤΑΝΙΑ',
    'ΜΑΥΡΙΚΙΟΣ',
    'ΜΑΓΙΟΤ',
    'ΜΕΞΙΚΟ',
    'ΟΜΟΣΠΟΝΔΕΣ ΠΟΛΙΤΕΙΕΣ ΤΗΣ ΜΙΚΡΟΝΗΣΙΑΣ',
    'ΜΟΛΔΑΒΙΑ',
    'ΜΟΝΑΚΟ',
    'ΜΟΓΓΟΛΙΑ',
    'ΜΟΝΤΣΕΡΡΑΤ',
    'ΜΑΡΟΚΟ',
    'ΜΟΖΑΜΒΙΚΗ',
    'ΜΙΑΝΜΑΡ (ΒΙΡΜΑΝΙΑ)',
    'ΝΑΜΙΜΠΙΑ',
    'ΝΑΟΥΡΟΥ',
    'ΝΕΠΑΛ',
    'ΟΛΛΑΝΔΙΑ (ΚΑΤΩ ΧΩΡΕΣ)',
    'ΟΛΛΑΝΔΙΚΕΣ ΑΝΤΙΛΛΕΣ',
    'ΝΕΑ ΚΑΛΗΔΟΝΙΑ',
    'ΝΕΑ ΖΗΛΑΝΔΙΑ',
    'ΝΙΚΑΡΑΓΟΥΑ',
    'ΝΙΓΗΡΑΣ',
    'ΝΙΓΗΡΙΑ',
    'ΝΙΟΥΕ',
    'ΝΗΣΙ ΝΟΡΦΟΛΚ',
    'ΒΟΡΕΙΕΣ ΜΑΡΙΑΝΕΣ ΝΗΣΟΙ',
    'ΝΟΡΒΗΓΙΑ',
    'ΟΜΑΝ',
    'ΠΑΚΙΣΤΑΝ',
    'ΠΑΛΑΟΥ',
    'ΔΥΤΙΚΗ ΟΧΘΗ (ΠΑΛΑΙΣΤΙΝΗ)',
    'ΠΑΝΑΜΑΣ',
    'ΠΑΠΟΥΑ ΝΕΑ ΓΟΥΙΝΕΑ',
    'ΠΑΡΑΓΟΥΑΗ',
    'ΠΕΡΟΥ',
    'ΦΙΛΙΠΠΙΝΕΣ',
    'ΝΗΣΙΑ ΠΙΤΚΕΡΝ',
    'ΠΟΛΩΝΙΑ',
    'ΠΟΡΤΟΓΑΛΙΑ',
    'ΠΟΥΕΡΤΟ ΡΙΚΟ',
    'ΚΑΤΑΡ',
    'ΡΕΪΝΙΟΝ',
    'ΡΟΥΜΑΝΙΑ',
    'ΡΩΣΙΑ',
    'ΡΟΥΑΝΤΑ',
    'ΝΗΣΟΣ ΑΓΙΑΣ ΕΛΕΝΗΣ',
    'ΑΓΙΟΣ ΧΡΙΣΤΟΦΟΡΟΣ ΚΑΙ ΝΕΒΙΣ',
    'ΑΓΙΑ ΛΟΥΚΙΑ',
    'ΣΑΙΝ ΠΙΕΡ ΚΑΙ ΜΙΚΕΛΟΝ',
    'ΑΓΙΟΣ ΒΙΚΕΝΤΙΟΣ ΚΑΙ ΓΡΕΝΑΔΙΝΕΣ',
    'ΣΑΜΟΑ',
    'ΑΓΙΟΣ ΜΑΡΙΝΟΣ',
    'ΣΑΟ ΤΟΜΕ ΚΑΙ ΠΡΙΝΣΙΠΕ',
    'ΣΑΟΥΔΙΚΗ ΑΡΑΒΙΑ',
    'ΣΕΝΕΓΑΛΗ',
    'ΣΕΡΒΙΑ',
    'ΣΕΫΧΕΛΛΕΣ',
    'ΣΙΕΡΑ ΛΕΟΝΕ',
    'ΣΙΓΚΑΠΟΥΡΗ',
    'ΣΛΟΒΑΚΙΑ',
    'ΣΛΟΒΕΝΙΑ',
    'ΝΗΣΟΙ ΣΟΛΟΜΩΝΤΑ',
    'ΣΟΜΑΛΙΑ',
    'ΝΟΤΙΑ ΑΦΡΙΚΗ',
    'ΙΣΠΑΝΙΑ',
    'ΣΡΙ ΛΑΝΚΑ',
    'ΣΟΥΔΑΝ',
    'ΣΟΥΡΙΝΑΜ',
    'ΣΒΑΛΜΠΑΡΝΤ',
    'ΣΟΥΑΖΙΛΑΝΔΗ',
    'ΣΟΥΗΔΙΑ',
    'ΕΛΒΕΤΙΑ',
    'ΣΥΡΙΑ',
    'ΤΑΪΒΑΝ',
    'ΤΑΤΖΙΚΙΣΤΑΝ',
    'ΤΑΝΖΑΝΙΑ',
    'ΤΑΪΛΑΝΔΗ',
    'ΑΝΑΤΟΛΙΚΟ ΤΙΜΟΡ',
    'ΤΟΓΚΟ',
    'ΤΟΚΕΛΑΟΥ',
    'ΤΟΓΚΑ',
    'ΤΡΙΝΙΝΤΑΝΤ ΚΑΙ ΤΟΜΠΑΓΚΟ',
    'ΤΥΝΗΣΙΑ',
    'ΤΟΥΡΚΙΑ',
    'ΤΟΥΡΚΜΕΝΙΣΤΑΝ',
    'ΤΕΡΚΣ ΚΑΙ ΚΕΙΚΟΣ',
    'ΤΟΥΒΑΛΟΥ',
    'ΟΥΓΚΑΝΤΑ',
    'ΟΥΚΡΑΝΙΑ',
    'ΗΝΩΜΕΝΑ ΑΡΑΒΙΚΑ ΕΜΙΡΑΤΑ',
    'ΗΝΩΜΕΝΟ ΒΑΣΙΛΕΙΟ',
    'ΗΝΩΜΕΝΕΣ ΠΟΛΙΤΕΙΕΣ ΑΜΕΡΙΚΗΣ',
    'ΟΥΡΟΥΓΟΥΑΗ',
    'ΟΥΖΜΠΕΚΙΣΤΑΝ',
    'ΒΑΝΟΥΑΤΟΥ',
    'ΒΕΝΕΖΟΥΕΛΑ',
    'ΒΙΕΤΝΑΜ',
    'ΒΡΕΤΑΝΙΚΕΣ ΠΑΡΘΕΝΟΙ ΝΗΣΟΙ',
    'ΑΜΕΡΙΚΑΝΙΚΕΣ ΠΑΡΘΕΝΟΙ ΝΗΣΟΙ',
    'ΟΥΑΛΙΣ ΚΑΙ ΦΟΥΤΟΥΝΑ',
    'ΔΥΤΙΚΗ ΣΑΧΑΡΑ',
    'ΥΕΜΕΝΗ',
    'ΖΑΜΠΙΑ',
    'ΖΙΜΠΑΜΠΟΥΕ',
    'ΜΑΥΡΟΒΟΥΝΙΟ'];

    export default countriesData;