<template>
  <div class="background-image">
    <NavBar />
    <v-container fluid class="bg-black py-10">
      <div>
        <div style="text-align: center;margin-left:20px;" primary-title>
          <h4 class="text-uppercase font-weight-bold">Εισοδος</h4>
        </div>
        <v-card-text class="px-8 py-4">
          <v-form @submit.prevent="login">
            <v-text-field v-model="userName" start-append prepend-icon="mdi-account" name="Username"
              :label="$t('username')" outlined dense></v-text-field>
            <v-text-field v-model="userPassword" start-append prepend-icon="mdi-lock" name="Password"
              :label="$t('password')" type="password" autocomplete="on" outlined dense></v-text-field>

            <div class="d-flex align-center justify-end" style="margin-top:-14px;">
              <router-link to="/forgot-password" class="ml-auto" style="z-index: 1000;">Ξεχάσατε τον κωδικό;</router-link>
            </div>

            <v-card-actions class="justify-center">
              <v-btn :loading="loadingLogin" type="submit" color="blue" large class="rounded-pill" style="margin-left:9%;color: white;" block>Εισοδος</v-btn>
            </v-card-actions>
          </v-form>

          <div class="text-center mt-4">
            <router-link to="/signup">Δεν έχετε λογαριασμό; Εγγραφή</router-link>
          </div>

        </v-card-text>
      </div>
    </v-container>
  </div>
</template>

<script>


import NavBar from "./NavBar.vue";
import { mapMutations } from 'vuex';

export default {
  name: "login",
  components: {

    NavBar,
  },

  data: () => ({
    loadingLogin: false,
    userName: "",
    userPassword: "",
  }),
  methods: {
    ...mapMutations('user', ['setIsLoggedIn']),
    login() {
      this.loadingLogin=true;
      this.$store.dispatch("user/authenticate", {
        username: this.userName,
        password: this.userPassword,
      }).then(() => {
        this.setIsLoggedIn(true)
        this.loadingLogin=false;
      }).catch((error) => {
        console.error(error);
        this.loadingLogin=false;
      });
    },
  },
};
</script>

<style scoped>
.v-card__actions {
  justify-content: space-between;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5%;
    background-color: #000000 !important;
    color: #ffffff;
    text-align: right !important;
}
.text-input {
  width: 100%;
  margin-bottom: 20px;
}
</style>
