<template>
  <v-container fluid fill-height>
    <NavBar></NavBar>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6 lg4>
        <v-card>
          <v-card-text>
            <div class="headline mb-2">Κάντε επαναφορά κωδικού</div>
            <p class="mb-6">Παρακαλώ εισάγετε το email σας</p>
            <v-form @submit.prevent="sendResetEmail">
              <v-text-field v-model="email" label="Εισάγετε το email σας" outlined dense required></v-text-field>
              <v-btn type="submit" color="primary" class="mt-4">
                <template v-if="!loading">Αποστολή</template>
                <template v-else>
                  <v-progress-circular indeterminate color="white" size="20"></v-progress-circular>
                </template>
              </v-btn>
            </v-form>
            <v-alert v-if="emailSent" type="success" dismissible outlined border="top" transition="scale-transition"
              class="mt-6">
              Email sent successfully! Check your inbox.
            </v-alert>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

  
<script>
import NavBar from "@/components/NavBar.vue";
import axios from 'axios';
import qs from 'qs';
import Swal from 'sweetalert2';

export default {
  name: "Home",
  components: {
    NavBar
  },
  data() {
    return {
      email: '',
      emailSent: false,
      loading: false
    };
  },
  methods: {
    async sendResetEmail() {
      try {
        this.loading = true;
        const formData = qs.stringify({ email: this.email });

        await axios.post(axios.defaults.baseURL+"/generate-reset-token/confirm", formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        const response = await axios.post(axios.defaults.baseURL+"/sendEmail", formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        if (response.status === 200) {
          this.emailSent = true;
          await this.showSuccessMessage();
          setTimeout(() => {
            this.$router.push('/login');
          }, 2000);
        }
      } catch (error) {
        console.error('Error sending email:', error);
      }
      finally {
        this.loading = false; 
      }
    },
    async showSuccessMessage() {
      await Swal.fire({
        icon: 'success',
        title: 'Email Sent',
        text: 'Check your inbox!',
        timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false
      });
    },
  },
};
</script>