import store from "@/state/store.js";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router/index.js";
import axios from "axios";
import VueI18n from "vue-i18n";
import i18n from "./i18n";
import VueSimpleAlert from "vue-simple-alert";
import Swal from "sweetalert2";


Vue.config.productionTip = false;

// axios.defaults.baseURL = "http://localhost:9090";
axios.defaults.baseURL = "https://back-apply.sse.gr";

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      Swal.fire({
        title: "Ανακατεύθυνση...",
        html: "Η συνεδρία σας έχει λήξει, παρακαλώ συνδεθείτε ξανά",
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      }).then(() => {
        store.dispatch('user/destroyToken').finally(() => {
          router.push("login");
        });
      });
      return Promise.reject('Unauthorized');
    }
    return Promise.reject(error);
  }
);

Vue.use(VueI18n);
Vue.use(VueSimpleAlert);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
