/* eslint-disable no-unused-vars */
import router from "@/router/index.js";
import store from "@/state/store";
import axios from "axios";
import Swal from 'sweetalert2';
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export default {
  namespaced: true,
  state: {
    authRequestBody: {
      username: "",
      password: "",
    },
    userRole: "",
    token: null,
    userId: " ",
    userName: " ",
    user: {},
    isLoggedIn: false,
  },

  mutations: {
    resetRole(state) {
      state.userRole = null;
    },
    setUserRole(state, role) {
      state.userRole = role;
    },
    retrieveToken(state, token) {
      state.token = token;
    },
    saveUser(state, user) {
      state.userObj = user;
    },
    destroyToken(state) {
      state.token = null;
    },
    setUser(state, value) {
      state.user = JSON.parse(JSON.stringify(value));
    },
    setUserId(state, id) {
      state.userId = id;
    },
    tempUserView(state, item) {
      state.tempUserView = item;
    },
    setUserName(state, name) {
      state.userName = name;
    },
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
  },

  actions: {
    authenticate: ({ context, commit }, authRequestBody) => {
      return new Promise((resolve, reject) => {
        axios
          .post(
            axios.defaults.baseURL + "/auth/authenticate",
            authRequestBody
          )
          .then(async (response) => {
            console.log(response);
            const token = response.data.jwt;
            const role = response.data.roles[0].authority

            commit("setUserName", authRequestBody.username);
            commit("retrieveToken", token);
            commit("setUserRole", role);
            commit("setIsLoggedIn", true);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + token;
            await axios.get(axios.defaults.baseURL + "/user/getUserByUsername/" + authRequestBody.username).then((res) => {
              commit("saveUser", res.data);
            }).catch((error) => {
              console.log(error);
            });
            resolve(true);
            router.push("/personaldata");
          })
          .catch((error) => {                
            Swal.fire({
              icon: "error",
              title: "Σφάλμα",
              text: "Τα στοιχεία δεν είναι σωστά",
              timer: 2000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showConfirmButton: false
            });
            
            reject(error);
          });
      });
    },



    retrieveUser: ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + store.state.user.token;
        axios
          .get("/getUser/{userName}?userName=" + state.userName)
          .then(({ data, status }) => {
            if (status === 200) {
              resolve(true);
              commit("setUser", data);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    viewUser: ({ commit }, item) => {
      return new Promise((resolve, reject) => {
        commit("tempUserView", item);
        resolve(true);
      });
    },
    registerUser: ({ commit }, userBody) => {
      async function showAlertMessage(icon, title, message) {
        await Swal.fire({
          icon: icon,
          title: title,
          text: message,
          timer: 2000,
          timerProgressBar: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false
        });
      }

      return new Promise((resolve, reject) => {
        axios
          .post("/auth/registerUser", userBody)
          .then(async ({ data, status }) => {
            if (status === 200) {
              resolve(true);
              await showAlertMessage("success", "Registration complete!", "User added successfully.");
              setTimeout(() => {
                router.push('/login');
              }, 1000);
            } else if (status === 409) {
              resolve(true);
              await showAlertMessage("error", "Προσοχή", "Το όνομα χρήστη υπάρχει ήδη στο σύστημα");
            }
          })
          .catch(async (error) => {
            // await this.showAlertMessage("error", "Παρουσιάστηκε προβλημα", "Παρακαλώ επικοινωνήστε με τον διαχειριστή του συστήματος");
            Swal.fire({
              icon: "info",
              title: "Προσοχή!",
              text: "Παρουσιάστηκε κάποιο πρόβλημα, επικοινωνήστε με τον διαχειριστή του συστήματος",
              timer: 2000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showConfirmButton: false
            });
            reject(error);
          });
      });
    },

    updateUserAction: async ({ commit }, userToBeUpdatedRequestBody) => {
      await axios.post("/user/updatepersonaldata", userToBeUpdatedRequestBody)
        .then(async ({ data, status }) => {
          if (status === 200) {
            console.log(status);
            console.log(data);
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Επιτυχής Επεξεργασία Στοιχείων",
              timer: 2000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              showConfirmButton: false
            });
          }
        })
        .catch(async (error) => {
          console.log(error)
        });
    },
    destroyToken: (context) => {
      return new Promise((resolve, reject) => {
        const username = context.state.userName;
        console.log('Username:', username);

        localStorage.clear();
        delete axios.defaults.headers.common["Authorization"];
        context.commit("destroyToken");
        context.commit("resetRole");
        sessionStorage.clear();
        resolve(true);
      });
    },
  },


  getters: {
    getUserRole(state) {
      return state.userRole;
    },
    getUser(state) {
      return state.user.userObj;
    },
    loggedIn(state) {
      return state.token !== null;
    },
    getUserId(state) {
      return state.userId;
    },
    getUserName(state) {
      return state.userName;
    },
    getToken(state) {
      return state.token;
    },
  },
};

