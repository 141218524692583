<template>
  <v-app>
    <v-main class="backgroundImage">
      <router-view></router-view>
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Footer from '../src/components/Footer.vue';
export default {
  name: "App",
  components: {
    Footer,
  },
};
</script>
<style scoped>
.apexcharts-toolbar {
  z-index: 0 !important;
}
footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5%;
  background-color: #000000 !important;
  color: #ffffff;
  text-align: right !important;
}

@media only screen and (max-width: 600px) {
  footer {
    font-size: 11px;
  }
}
</style>
