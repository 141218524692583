<template>
  <div>
    <NavBar />
    <h2 class="title">Please select your preferred language</h2>
    <v-item-group mandatory>
      <v-container>
        <div class="container">
          <div
            v-for="country in countries"
            :key="country.tag"
            class="countryDiv"
          >
            <v-item v-slot="{ active }">
              <v-card
                :color="active ? 'primary' : ''"
                class="d-flex align-center"
                dark
                height="100%"
                @click="selectLanguage(country.tag)"
              >
                <v-img
                  max-height="100%"
                  max-width="100%"
                  :src="country.image"
                ></v-img>
              </v-card>
            </v-item>
          </div>
        </div>
      </v-container>
    </v-item-group>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import NavBar from "../NavBar.vue";

export default {
  components: {
    NavBar,
  },
  data: () => ({
    countries: [
      {
        tag: "en",
        image: "https://flagicons.lipis.dev/flags/4x3/gb.svg",
      },
      {
        tag: "gr",
        image: "https://flagicons.lipis.dev/flags/4x3/gr.svg",
      },
      {
        tag: "ge",
        image: "https://flagicons.lipis.dev/flags/4x3/de.svg",
      },
      {
        tag: "la",
        image: "https://flagicons.lipis.dev/flags/4x3/lv.svg",
      },
      {
        tag: "ro",
        image: "https://flagicons.lipis.dev/flags/4x3/ro.svg",
      },
    ],
  }),
  methods: {
    ...mapActions("language", ["pickLanguage"]),
    selectLanguage(tag) {
      this.pickLanguage(tag);
      this.$router.push("/login").catch(() => {});
    },
  },
};
</script>

<style>
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0;
}
.countryDiv {
  margin: 10px;
}
.title {
  text-align: center;
  padding: 1rem;
}
</style>